import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PricingPlans() {
    return (
        <div className='pricing-container'>
            <div>
                <h1 className='pricing-title'>PRICING</h1>
                <p className='pricing-subtext'>Find the right, fixed-rate plan<br /><span style={{fontSize:"38px"}}>for your needs</span></p>
            </div>
            <p style={{textAlign:'center', fontSize: "22px"}}>Welcome to the beginning of a transformative experience! We're thrilled to introduce ProjectLoop, a groundbreaking solution designed with your needs and aspirations in mind.  Try comparing us to the competition, you will see how AWESOME are prices are.</p>
            
            <div>
                
                <p className='pricing-subtext' style={{marginBottom:'0px'}}><span style={{fontSize:"28px"}}>Our By User "bucket" plans give you the best of both worlds, flat-rate pricing monthly and by user pricing.</span></p>
            </div>
            <div className='pricing-plan-container'>
                
                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Free - 3 Users</div>
                        <div className='plan-description'>We are offering a free for life plan to try us out and experience the difference.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$0</div>
                            <div className='plan-pricing-text'>
                                <p> CAD / Month + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly&subplan=free">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>unlimited</u> access for <u>up to 3 users and 1 project</u>:</div>
                    <div className='plan-features'>
                        <ul>
                            {/* <li>
                                <CheckOutlined /> Drawings
                                <br />
                                <div style={{marginLeft:'30px'}}>
                                    ($29/mo Add-on)
                                </div>
                            </li> */}
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents (less than 100mb)
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Access
                            </li>
                            
                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                            Terms & Conditions apply, you are getting unlimited access to a state of the art platform.  Certain restrictions apply.
                            <br />
                            
                        </p>
                    </div>
                </div>


                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Bronze - 10 Users</div>
                        <div className='plan-description'>Our starting user plan. This plan offers a lot without breaking the bank.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$299</div>
                            <div className='plan-pricing-text'>
                                <p> Month + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly&subplan=bronze">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>up to 5 active</u> Projects sites:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings Access
                                <br />
                                <div style={{marginLeft:'30px'}}>
                                    ($149/mo Add-on)
                                </div>
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Access
                            </li>
                            {/* <li>
                                <CheckOutlined /> 20% off SafetyLoop when bundled
                            </li> */}

                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                        Terms & Conditions apply, you are getting unlimited access to a state of the art platform.  Certain restrictions apply.
                        </p>
                    </div>
                </div>

                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Silver - 30 Users</div>
                        <div className='plan-description'>Our mid-level user plan, if you need to support more people this plan is for you.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$599</div>
                            <div className='plan-pricing-text'>
                                <p> Month + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly&subplan=silver">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>unlimited</u> Projects sites:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings Access 
                                <br />
                                <div style={{marginLeft:'30px'}}>
                                    ($399/mo Add-on)
                                </div>
                                
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Access
                            </li>
                            {/* <li>
                                <CheckOutlined /> 20% off SafetyLoop when bundled
                            </li> */}

                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                        Terms & Conditions apply, you are getting unlimited access to a state of the art platform.  Certain restrictions apply.
                        </p>
                    </div>
                </div>

                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Gold - 100 Users</div>
                        <div className='plan-description'>Our Gold plan, this one will support most companies in our system.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$999</div>
                            <div className='plan-pricing-text'>
                                <p> Month + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly&subplan=gold">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>unlimited </u>Projects sites:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings Access
                                <br />
                                <div style={{marginLeft:'30px'}}>
                                    ($599/mo Add-on)
                                </div>
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Access
                            </li>
                            {/* <li>
                                <CheckOutlined /> 20% off SafetyLoop when bundled
                            </li> */}

                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                        Terms & Conditions apply, you are getting unlimited access to a state of the art platform.  Certain restrictions apply.
                        </p>
                    </div>
                </div>

                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>By Project</div>
                        <div className='plan-description'>Need to bill by project? We got you covered.  Charge by project site a flat fee.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$999</div>
                            <div className='plan-pricing-text'>
                                <p> Month + Tax / Per Project<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly&subplan=project">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Per Project:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings Access
                                <br />
                                <div style={{marginLeft:'30px'}}>
                                    ($399/mo Add-on Per project)
                                </div>
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Access
                            </li>
                            <li>
                                <CheckOutlined /> Up to 200 total users
                            </li>
                            {/* <li>
                                <CheckOutlined /> 20% off SafetyLoop when bundled
                            </li> */}

                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                        Terms & Conditions apply, you are getting unlimited access to a state of the art platform.  Certain restrictions apply.
                        </p>
                    </div>
                </div>

                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Early Adopter - Annual</div>
                        <div className='plan-description'>We know we are new! So we are offering an AMAZING deal, get a set rate for the first year that we will never offer again. </div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$12,000</div>
                            <div className='plan-pricing-text'>
                                <p> CAD / Year + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings*
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div> */}
                
            
                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Professional</div>
                        <div className='plan-description'>For organizations seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$50</div>
                            <div className='plan-pricing-text'>
                                <p>/Seat/Month<br />When billed monthly</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Professional</div>
                        <div className='plan-description'>For organizations seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$50</div>
                            <div className='plan-pricing-text'>
                                <p>/Seat/Month<br />When billed monthly</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Enterprise</div>
                        <div className='plan-description'>For enterprises seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            
                            <div className='plan-pricing-text'>
                                <p>Sign up and we will contact you during the free trial along with a custom quote.</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=enterprise">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>All the benefits of Professional, and:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Access to Custom Reports/Analytics
                            </li>
                            <li>
                                <CheckOutlined /> Dedicated Support Plans
                            </li>
                            <li>
                                <CheckOutlined /> Annual Saving Plans
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
