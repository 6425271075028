import React from 'react';
import { Divider } from 'antd'
import { useSearchParams } from 'react-router-dom';

export default function OrderSummary() {
    
        const [searchParams] = useSearchParams();
        
        const plan = searchParams.get("plan");
        const subplan = searchParams.get("subplan");
        // debugger;
    return (
        <div className='order-summary'>
            <h2>Order Summary</h2>
            <div className='order-summary-item'>
                <div className='summary-title'>Current Plan</div>
                <div className='summary-value' style={{ textTransform: "capitalize" }}>
                {subplan === "free" 
                    ? "Free" 
                    : subplan === "bronze" 
                    ? "Bronze" 
                    : subplan === "silver" 
                    ? "Silver" 
                    : subplan === "gold" 
                    ? "Gold" 
                    : subplan === "project" 
                    ? "Project Based"
                    : "Custom Quote"}  
                </div>
            </div>
            <div className='order-summary-item'>
                <div className='summary-title'>Price per user seat</div>
                <div className='summary-value'>
                {subplan === "free" 
                        ? "$0" 
                        : subplan === "bronze" 
                        ? "$29.90" 
                        : subplan === "silver" 
                        ? "$19.90" 
                        : subplan === "gold" 
                        ? "$9.99" 
                        : subplan === "project" 
                        ? "-"
                        : "Custom Quote"}  
                </div>
            </div>
            <div className='order-summary-item'>
                <div className='summary-title'>Number of active user seats*</div>
                <div className='summary-value'>
                    {subplan === "free" 
                        ? "Up to 3 Users" 
                        : subplan === "bronze" 
                        ? "Up to 10 Users" 
                        : subplan === "silver" 
                        ? "Up to 30 Users" 
                        : subplan === "gold" 
                        ? "Up to 100 Users" 
                        : subplan === "project" 
                        ? "Unlimited Users"
                        : "Custom Quote"}  
                </div>
            </div>
            <Divider />
            <div className='order-summary-item'>
                <div className='summary-title'><b>Total per month<br /></b></div>
                <div className='summary-value'><b>
                {subplan === "free" 
                    ? "$0 CAD + Tax" 
                    : subplan === "bronze" 
                    ? "$299 CAD + Tax" 
                    : subplan === "silver" 
                    ? "$599 CAD + Tax" 
                    : subplan === "gold" 
                    ? "$999 CAD + Tax" 
                    : subplan === "project" 
                    ? "$999 CAD + Tax / Project"
                    : "Custom Quote"}    
                </b>
                </div>
            </div>
        </div>
    )
}
