/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Select, Button, Radio, Upload, Row, Col, Input, Switch } from 'antd';
import { UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Wrapper } from '../../Pipeline/style';
import formRules from '../../DailyLogsNew/components/Forms/formRules';
import showNotification from '../../../components/ShowNotification';
import PdfIcon from '../../../images/pdf-image.png';
import VideoIcon from '../../../images/videoIcon.png';
import IMGIcon from '../../../images/location-img.png';
import csvImg from '../../../images/csv.svg';
import GreyBoxComponent from '../../RFI/Components/CreateRFI/GreyBoxComponent';
import { ScheduleIcons } from '../ScheduleIcons';

export const AttachmentItem = ({
  item,
  action,
  handleItemDelete,
  isDelete,
  onlyFileName,
  isPreview = false
}) => {
  if (onlyFileName) {
    return (
      <Row
        justify="space-between"
        style={{
          width: '100%',
          marginBlock: 10,
          background: '#F6F6F6',
          borderRadius: '4px',
          padding: 12,
          marginInline: 0,
          textAlign: 'left'
        }}>
        <Col span={20}>
          <div style={{ fontWeight: 'bold' }}>{item?.name || item?.file_name}</div>
          <div style={{ color: '#909090' }}>{Number(item.size / (1024 * 1024)).toFixed(2)}MB</div>
        </Col>
        <Col
          style={{ cursor: 'pointer' }}
          onClick={() => (isDelete ? handleItemDelete(item.id, item) : action.remove(item))}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M4 7H20"
              stroke="#FF3030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 11V17"
              stroke="#FF3030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 11V17"
              stroke="#FF3030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
              stroke="#FF3030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
              stroke="#FF3030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Col>
      </Row>
    );
  }
  const isPDF = item?.name?.endsWith('.pdf') || item?.file_name?.endsWith('.pdf');
  const isVideo = item?.name?.endsWith('.mp4') || item?.file_name?.endsWith('.mp4');
  const isExcel =
    item?.name?.endsWith('.csv') ||
    item?.name?.endsWith('.xlsx') ||
    item?.file_name?.endsWith('.csv') ||
    item?.file_name?.endsWith('.xlsx');
  return (
    <Row
      gutter={16}
      style={{
        width: '100%',
        marginBlock: 10,
        background: '#F6F6F6',
        borderRadius: '4px',
        padding: 12,
        marginInline: 0
      }}
      align="middle">
      {!isDelete && !isPreview && (
        <Col span={2}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none">
            <path
              d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
              stroke="#00BA25"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 16.0002L14.6667 18.6668L20 13.3335"
              stroke="#00BA25"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Col>
      )}
      <Col span={isDelete ? 24 : 22}>
        <Row align="middle" gutter={16}>
          {!item?.preview_url ? (
            <Col span={3}>
              {isPDF && <img src={PdfIcon} alt="pdf-icon" />}
              {isVideo && <img src={VideoIcon} alt="video-icon" />}
              {isExcel && <img src={csvImg} alt="csv" />}
              {!isPDF && !isVideo && !isExcel && <img src={IMGIcon} alt="img-icon" />}
            </Col>
          ) : (
            <img src={item.preview_url} height="32px" width="32px" style={{ objectFit: 'cover' }} />
          )}
          <Col span={21}>
            <Row justify="space-between" align="middle">
              <Col>
                <div style={{ fontWeight: 'bold' }}>{item?.name || item?.file_name}</div>
                <div style={{ color: '#909090' }}>
                  {Number(item.size / (1024 * 1024)).toFixed(2)}MB
                </div>
              </Col>
              {!isPreview && (
                <Col
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    isDelete ? handleItemDelete(item.id, item) : action.remove(item)
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M4 7H20"
                      stroke="#FF3030"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 11V17"
                      stroke="#FF3030"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 11V17"
                      stroke="#FF3030"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                      stroke="#FF3030"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                      stroke="#FF3030"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const AttachmentList = ({ list, action, handleItemDelete, isDelete, onlyFileName }) => {
  return (
    <>
      {list?.length > 0 && (
        <label>
          <PaperClipOutlined /> {isDelete ? 'Existing file' : 'Attachment file'}
        </label>
      )}
      {list?.map((item) => {
        return (
          <AttachmentItem
            item={item}
            action={action}
            isDelete={isDelete}
            handleItemDelete={handleItemDelete}
            onlyFileName={onlyFileName}
          />
        );
      })}
    </>
  );
};

const UploadForm = ({
  handleCreate,
  optionsList,
  setUpload,
  cName,
  isLoading,
  baseData,
  isExistingUpload
}) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  // const { data: baseData } = useGetMasterScheduleDropdownData(projectId);
  const { schedules = [] } = baseData?.data || {};
  const [fileList, setFileList] = useState([]);
  const onFinish = (values) => {
    if (!fileList?.length) {
      showNotification({ type: 'error', message: 'Upload a file to proceed' });
      return;
    }
    handleCreate({ ...values, fileList });
  };
  const UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      form.resetFields(['files']);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const maxSize = 25; // Set your maximum file size limit in MB
      if (file.size / 1024 / 1024 > maxSize) {
        showNotification({ type: 'error', message: `File must be smaller than ${maxSize}MB` });
        return false;
      }
      const isDuplicate = fileList.some((existingFile) => existingFile.name === file.name);
      if (isDuplicate) {
        return showNotification({ type: 'error', message: `${file.name} is already in the list.` });
      }
      setFileList([file]);
      return false;
    },
    fileList
  };
  return (
    <Wrapper>
      <Form
        initialValues={{ type: isExistingUpload ? '1' : '2' }}
        name="addCustomRoleForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        {schedules.length > 0 ? null : (
          <div
            span={24}
            className="d-flex justify-content-between align-items-center"
            style={{
              background: '#EBF3FF',
              borderRadius: '8px',
              padding: '15px',
              margin: '2px 5px 14px'
            }}>
            <div className="d-flex align-items-center">
              {ScheduleIcons.info}
              <div className="flex-column">
                <p style={{ color: '#3284FF', marginLeft: 10 }}>
                  Can't choose existing schedules.since no schedules have been created as of yet.
                  Only option to create new schedule.
                </p>
              </div>
            </div>
          </div>
        )}
        {isExistingUpload ? (
          <Form.Item
            className="upload-radio"
            label="Choose Type"
            name="type"
            defaultValue="2"
            rules={formRules.setRequired('Type')}>
            <Radio.Group optionType="button" style={{ display: 'flex' }}>
              {optionsList?.map((e) => (
                <Radio.Button
                  style={{ marginRight: 10, height: 80, paddingBlock: 10, flex: 1 }}
                  value={e.value}
                  optionType="button">
                  <Row gutter={16} align="middle" justify="center">
                    <Col>{e.icon}</Col>
                    <Col>
                      <Row style={{ color: '#080808', fontSize: '19px', fontWeight: '700' }}>
                        {e.label}
                      </Row>
                      <Row style={{ color: '#909090', fontSize: '13px', lineHeight: '1.5rem' }}>
                        {e.description}
                      </Row>
                    </Col>
                  </Row>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        ) : (
          <Form.Item className="upload-radio" name="type" value="2" hidden />
        )}

        <Form.Item noStyle dependencies={['type']} shouldUpdate>
          {({ getFieldValue }) => {
            const isExisting = getFieldValue('type') === '1' && isExistingUpload;
            return isExisting && schedules.length ? (
              <Form.Item
                label={`Existing ${cName}`}
                name="parent_id"
                rules={formRules.setRequired(`Existing ${cName}`)}>
                <Select
                  placeholder="Select"
                  optionFilterProp="label"
                  optionLabelProp="label"
                  options={
                    schedules?.map((e) => ({
                      label: e?.key || e,
                      value: e?.value || e,
                      key: e?.value
                    })) || []
                  }
                  maxTagCount="responsive"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={`New ${cName}`}
                name="name"
                rules={formRules.setRequired(`New ${cName}`)}>
                <Input />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name="files"
          label="Upload File"
          style={{ marginBottom: 0 }}
          rules={[{ ...formRules.required[0], message: 'Updload a file to proceed...' }]}>
          <Upload.Dragger
            {...UploadProps}
            accept=".pdf"
            itemRender={(_originNode, file, list, action) => (
              <>
                <Row
                  justify="start"
                  className="d-flex align-items-center"
                  style={{ paddingTop: '5px', color: '#5C5C5C' }}>
                  <svg
                    style={{ marginRight: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none">
                    <g clipPath="url(#clip0_13237_61272)">
                      <path
                        d="M9.9998 5.16656L5.66647 9.49989C5.40125 9.76511 5.25226 10.1248 5.25226 10.4999C5.25226 10.875 5.40125 11.2347 5.66647 11.4999C5.93169 11.7651 6.2914 11.9141 6.66647 11.9141C7.04154 11.9141 7.40125 11.7651 7.66647 11.4999L11.9998 7.16656C12.5302 6.63613 12.8282 5.91671 12.8282 5.16656C12.8282 4.41642 12.5302 3.69699 11.9998 3.16656C11.4694 2.63613 10.7499 2.33813 9.9998 2.33813C9.24966 2.33813 8.53024 2.63613 7.9998 3.16656L3.66647 7.49989C2.87082 8.29554 2.42383 9.37468 2.42383 10.4999C2.42383 11.6251 2.87082 12.7042 3.66647 13.4999C4.46212 14.2955 5.54125 14.7425 6.66647 14.7425C7.79169 14.7425 8.87082 14.2955 9.66647 13.4999L13.9998 9.16656"
                        stroke="#5C5C5C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13237_61272">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  Attachment file
                </Row>
                <AttachmentItem item={file} action={action} />
              </>
            )}>
            <Row
              gutter={16}
              className="p-12 no-margin-lr flex-row item-center justify-content-between">
              <Col span={11} className="drag" style={{ height: 42 }}>
                <Button size="large" type="dashed" className="w-100 bg-grey">
                  Drag File Here
                </Button>
              </Col>
              <Col span={2} className="fc-grey fs-20">
                Or
              </Col>
              <Col span={11} style={{ height: 42 }} className="bg-lite-primary text-center">
                <Button icon={<UploadOutlined />} size="large" type="link">
                  Add New Attachment
                </Button>
              </Col>
            </Row>
          </Upload.Dragger>
        </Form.Item>
        <GreyBoxComponent style={{ marginBottom: '10px' }}>
          <div className="form-group-item table-row-light">
            <Col className="d-flex align-items-center">
              <Form.Item name="private" valuePropName="checked">
                <Switch />
              </Form.Item>
              <h3
                style={{
                  marginLeft: '10px',
                  color: '#080808',
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                Private{' '}
                <span
                  style={{
                    display: 'block',
                    color: '#909090',
                    fontSize: '12px',
                    fontWeight: 400
                  }}>
                  Making an item private grants access only to admins in your projects
                </span>
              </h3>
            </Col>
          </div>
        </GreyBoxComponent>
        <div className="flex-row gap-16 item-center" style={{ justifyContent: 'flex-end' }}>
          <Button
            htmlType="button"
            size="large"
            className="cancel-btn"
            onClick={() => setUpload(false)}>
            Close
          </Button>
          <Button
            loading={isLoading}
            htmlType="submit"
            size="large"
            type="primary"
            className="project-btn">
            Upload
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default UploadForm;
